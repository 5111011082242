<script setup lang="ts">
import { ref } from 'vue';
import UserManager from '../sdk/UserManager';
import { useRouter } from 'vue-router';

const showMobileMenu = defineModel('showMobileMenu', { type: Boolean, })


const router = useRouter();
const userManager = new UserManager();

const onCloseMobileMenu = function() {
  showMobileMenu.value = false;
}

const onLogoutClicked = async function() {
  await userManager.logout();
  showMobileMenu.value = false;
  router.push({ name: 'Logout' })
}

const onChangeUserProfileClicked = async function() {
  showMobileMenu.value = false;
  router.push({ name: 'UpdateUser' })
}

</script>

<template>
    <div v-if="showMobileMenu" style="background-color: white;height: calc(100% - 72px);top: 72px;position: absolute;width: 100%;">
    <div style="padding: 2rem;">
      <div class="header-bar">
        <div class="header-bar-left"><h4>Hallo {{ userManager.getUserName() }}</h4></div>
        <div class="header-bar-right"><span @click="onCloseMobileMenu" style="width: 4rem;text-align: center;display: block;cursor: pointer;">X</span></div>
      </div>

      <div class="menu-item" style="margin-top: 2rem;" @click="onChangeUserProfileClicked">Benutzerkonto anpassen</div>
      
      <div class="menu-item" style="margin-top: 2rem;" @click="onLogoutClicked">Abmelden</div>
    </div>
  </div>
</template>

<style scoped>
    
  .header-bar {
    display: flex;
    justify-content: space-between;
  }

  .menu-item {
    cursor: pointer;
  }

</style>
