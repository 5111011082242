<script setup lang="ts">
import { onActivated } from 'vue';
import EmailTemplate from '../../sdk/models/EmailTemplate';
import { EntityListControl } from '../entitylist/EntityListControl';
import SendTestEmail from './SendTestEmail.vue';
import FormatHelper from '../../sdk/helpers/FormatHelper';

var entityList = new EntityListControl<EmailTemplate>('emailtemplates');

onActivated(async () => {
  await entityList.retrieveAllAsync();
})

const sendTestMail = function() {
    alert('send');
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Email Template-Liste</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <div class="list-row list-header row">
                        <div class="col-xs-2">Erstellt Am</div>
                        <div class="col-xs-3">Code</div>
                        <div class="col-xs-5">Name</div>
                        <div class="col-xs-2"></div>
                    </div>

                    <div class="list-row row" v-for="entity in entityList.data.entities">
                        <div class="col-xs-2">{{ FormatHelper.stringToDateTime(entity.Timestamp!) }}</div>
                        <div class="col-xs-3">{{ entity.RowKey }}</div>
                        <div class="col-xs-5">{{ entity.Name }}</div>
                        <div class="col-xs-2 list-button-column">
                            <button type="submit" name="createNewEntity" class="buttonstyle" style="height:3rem" @click="entityList.editEntity(entity)">
                                <span class="">Bearbeiten</span>
                            </button>

                            <button type="submit" name="deleteEntity" class="buttonstyle" style="height:3rem;margin-left: 1rem;" @click="entityList.deleteEntityAsync(entity)">
                                <span class="">Löschen</span>
                            </button>
                        </div>
                    </div>

                    <button type="submit" name="submit" class="buttonstyle" @click="entityList.createNewEntity" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Neues Emailtemplate erstellen</span>
                    </button>
   
                </div>
            </div>
        </div>
<!--
        <SendTestEmail :email-templates="entityList.data.entities as EmailTemplate[]" />
        -->
    </div>
</template>

<style scoped>

.list-row div {
    height: 4rem;
}

.list-button-column {
    align-content: right;
    display: flex;
    justify-content: right;
}
 .label-style {
        font-weight: 400;
        line-height: 1.5;
        font-family: Roboto,sans-serif;
        color: #323335;
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }  


</style>
