import AzureEnvironment from "./AzureEnvironment";

export default class AzureFunctionCall {

    azureFunctionUrl: string =""

    constructor() {
        this.azureFunctionUrl = AzureFunctionCall.GetAzureFunctionUrl();
    }

    async Get(functionName:string) {
        const request: RequestInit =  {
            method: 'GET',
        }
        const functionUrl: string = this.azureFunctionUrl + functionName;
        return await fetch(functionUrl, request);
    }

    async Post(functionName:string, bodyObject: any) {
        const request: RequestInit =  {
            method: 'POST',
            body: JSON.stringify(bodyObject)
        }
        const functionUrl: string = this.azureFunctionUrl + functionName;
        
        var result = await fetch(functionUrl, request);
        if (result.ok) {
            return result;
        }
        else if (result.status == 400) {
            var message = await result.text();
            throw Error(message);
        } else {
            throw Error("UnexpectedError");
        }
    }

    async Put(functionName:string, bodyObject: any) {
        const request: RequestInit =  {
            method: 'PUT',
            body: JSON.stringify(bodyObject)
        }
        const functionUrl: string = this.azureFunctionUrl + functionName;
        return await fetch(functionUrl, request);
    }

    async Delete(functionName:string, bodyObject: any) {
        const request: RequestInit =  {
            method: 'DELETE',
            body: JSON.stringify(bodyObject)
        }
        const functionUrl: string = this.azureFunctionUrl + functionName;
        return await fetch(functionUrl, request);
    }

    static GetAzureFunctionUrl() {
        if(window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1") {
            return AzureEnvironment.GetAzureBaseUrl(true) + "api/";
        } else {
            return AzureEnvironment.GetAzureBaseUrl(true) + "api/";
        }
    }
}