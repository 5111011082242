<script setup lang="ts">
import { onActivated, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import AzureEnvironment from '../../sdk/AzureEnvironment';
import EntityApi from '../../sdk/EntityApi';
import Asset from '../../sdk/models/Asset';
import { EntityFormControl } from '../entityform/EntityFormControl';
import Overlay from '../Overlay.vue';

const formControl = new EntityFormControl('emailtemplates');

const assetsApi = new EntityApi("assets");

const route = useRoute();

const data = reactive({
        assets: [] as Asset[],
        attachments: [] as Asset[],
});

const showOverlay = ref(false);

onActivated(async () => {
    await formControl.initializeAsync(route.params.rowKey as string);
    data.assets = await assetsApi.retrieveAll();

    data.attachments = [];
    if(formControl.data.entity.Attachments != null) {
        var loadedAttachments = formControl.data.entity.Attachments.split(',') as Array<string>;
        data.attachments = data.assets.filter(asset => loadedAttachments.includes(asset.RowKey!));
    }
})

const addAttachment = function() {
    temporaryAttachments = [];
    showOverlay.value = true;
}

const removeAttachment = function(attachment:Asset) {
    data.attachments = data.attachments.filter(currentAsset => currentAsset.RowKey !== attachment.RowKey);
}

const onSave = async function() {
    formControl.data.entity.Attachments = calculateAttachmentKeys();
    data.assets = [];
    await formControl.saveEntity();
}

const calculateAttachmentKeys = function():string {
    let result:string = "";
    data.attachments.forEach(attachment => {
        result += attachment.RowKey + ",";
    });
    result = result.replace(/,$/, '');
    return result;
}

// Overlay Selection
let temporaryAttachments = [] as Asset[];
const overlayAssetSelected = function(e:any, asset:Asset) {
    if(e.srcElement.checked) {
        temporaryAttachments.push(asset);
    } else {
        temporaryAttachments = temporaryAttachments.filter(currentAsset => currentAsset.RowKey !== asset.RowKey);
    }
}

const overlayAddAssets = function() {
    temporaryAttachments.forEach(tempAsset =>  {
        let filteredAssets = data.attachments.filter(currentAsset => currentAsset.RowKey === tempAsset.RowKey);
        if(filteredAssets.length == 0) {
            data.attachments.push(tempAsset);
        }
    });
    showOverlay.value = false;
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Email Template Form</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <label for="entityCode" class="label-style">Emailtemplate-Code</label>
                    <input type="text" name="entityCode" id="entityCode" class="textbox-style" v-model=" formControl.data.entity.RowKey" :disabled="formControl.data.isCreate == false">
                    <label for="entityName" class="label-style">Emailtemplate-Name</label>
                    <input type="text" name="entityName" id="entityName" class="textbox-style" v-model="formControl.data.entity.Name">
                    
                    <div class="row">
                        <div class="col-xs-6">
                            <label for="fromName" class="label-style">From Name</label>
                            <input type="text" name="fromName" id="fromName" class="textbox-style" v-model="formControl.data.entity.FromName">
                        </div>
                        <div class="col-xs-6">
                            <label for="fromEmail" class="label-style">From Email</label>
                            <input type="text" name="fromEmail" id="fromEmail" class="textbox-style" v-model="formControl.data.entity.FromEmail">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6">
                            <label for="toName" class="label-style">To Name</label>
                            <input type="text" name="toName" id="toName" class="textbox-style" v-model="formControl.data.entity.ToName">
                        </div>
                        <div class="col-xs-6">
                            <label for="toEmail" class="label-style">To Email</label>
                            <input type="text" name="toEmail" id="toEmail" class="textbox-style" v-model="formControl.data.entity.ToEmail">
                        </div>
                    </div>                    
                    
                    <label for="subject" class="label-style">Subject</label>
                    <input type="text" name="subject" id="subject" class="textbox-style" v-model="formControl.data.entity.Subject">
                    <label for="emailHtml" class="label-style">Email HTML</label>
                    <textarea type="text" rows="6" name="emailHtml" id="emailHtml" class="textarea-style" v-model="formControl.data.entity.ContentHtml"></textarea>
                    <label for="emailText" class="label-style">Email Text</label>
                    <textarea type="text" rows="6" name="emailText" id="emailText" class="textarea-style" v-model="formControl.data.entity.ContentText"></textarea>
                    
                    <h4>Attachments</h4>
                    <hr />
                    <div v-for="attachment in data.attachments" class="row">
                        <div class="col-xs-10">{{attachment.Name}}</div>
                        <div class="col-xs-2">
                            <button name="submit" class="buttonstyle" @click="removeAttachment(attachment)" style="float:right">
                                <span>Löschen</span>
                            </button>
                        </div>
                    </div>
                    <div>
                       <button name="submit" class="buttonstyle" @click="addAttachment" style="margin-top:1rem; margin-bottom: 1rem;">
                            <span>Hinzufügen</span>
                        </button>
                    </div>
                    <hr />
                    <div style="display: flex;">
                        <button name="submit" class="buttonstyle" @click="formControl.cancel" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span>Abbrechen</span>
                        </button>
                        <button name="submit" class="buttonstyle" @click="onSave" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span>Speichern</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>       
        
        <Overlay v-model:isvisible="showOverlay">
        <div class="overlay-content">
            <h3>Attachment</h3>
            <div v-for="asset in data.assets" class="row">
                <div class="col-xs-1">
                    <input type="checkbox" :id="asset.RowKey!" :name="asset.Name!" @change="overlayAssetSelected($event, asset)">
                </div>
                
                <div class="col-xs-10">{{ asset.Name }}</div>
            </div>

            <div>
                <button name="submit" class="buttonstyle" @click="overlayAddAssets" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                    <span class="">Ausgewählte Hinzufügen</span>
                </button>
            </div>
        </div>
        </Overlay>

    </div>
</template>

<style scoped>

    .overlay-content {
        padding: 1rem;
    }

    div[class^="col-"] {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .label-style {
        font-weight: 400;
        line-height: 1.5;
        font-family: Roboto,sans-serif;
        color: #323335;
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }    

</style>
