<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref } from 'vue'
import Login from '../../sdk/models/Login'
import AuthenticationApi from '../../sdk/AuthenticationApi'
import Overlay from '../Overlay.vue';
import PageTitle from '../controls/PageTitle.vue';
import WebHelper from '../../sdk/helpers/WebHelper';

const router = useRouter();
const route = useRoute();

const authApi = new AuthenticationApi()
const data = reactive({ 
  login: new Login(),
  loginInProgress: false,
  loginErrorOccured: false,
})

const loginClicked = async function() {
    data.login.Type = "userpass";
    try {
      data.loginErrorOccured = false;
      data.loginInProgress = true;
      const loginResult = await authApi.login(data.login);
      console.log("LogResult");
      console.log(loginResult);
    }
    catch(error) {
      data.loginInProgress = false;
      data.loginErrorOccured = true;
      return;
    }

    try {
      const lastValidRoute = WebHelper.getStorageValue("lastValidRoute");
      if(lastValidRoute != null) {
        router.push({ name: lastValidRoute });
      } else {
        router.back();
      }
    }
    catch {
      router.push("/");
    }
}

const registerClicked = async function() {
  router.push({ name: "Register" });
}

</script>

<template>
  <div class="form-container">

    <PageTitle text="Login"></PageTitle>

    <div class="row">
      <div class="col-xs-12">
        <div class="box-row card cardshadow">
            <input type="text" name="user" id="user" class="textbox-style" v-model="data.login.User" placeholder="Email">
            <input type="password" name="productName" id="productName" class="textbox-style" v-model="data.login.Password" placeholder="Passwort" style="margin-top: 1rem;">

            <button type="submit" name="login" class="buttonstyle" style="margin-top:2rem; margin-bottom: 1rem;" @click="loginClicked">
                <span class="">Login</span>
            </button>
            <label v-if="data.loginErrorOccured" class="label-style label-warning">Ein Fehler ist augetreten. Bitte später nochmals probieren.</label>

            <div class="divider"></div>
            <p>Du bist noch nicht registriert?</p>
            <button type="submit" name="register" class="buttonstyle" style="margin-top:2rem; margin-bottom: 1rem;" @click="registerClicked">
                <span class="">Jetzt registrieren</span>
            </button>
        </div>
      </div>
    </div>

    <Overlay v-model:isvisible="data.loginInProgress" :is-closable="false" height="80px" width="200px" rounded-corners="0.5rem">
        <div style="display: flex;justify-content: center;align-items: center;height: 100%;"><div style="color: gray;">Login l&auml;uft...</div></div>
    </Overlay>
  </div>

  
</template>

<style scoped>
.card {
  background-color: white;
    padding: 2rem
}

.divider {
  border-bottom: 1px solid lightgray;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

</style>
