<script setup lang="ts">import { reactive } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import EntityApi from '../../sdk/EntityApi';
import Product from '../../sdk/models/Product';
import FormatHelper from '../../sdk/helpers/FormatHelper';

const router = useRouter();  

class ProductList {
    products: Product[] = [];
}

const data = reactive(new ProductList());

const productApi = new EntityApi('products');
data.products = await productApi.retrieveAll();

const createNewProduct = function() {
    router.push("/admin/products/add");
}

const editProduct = function(product: Product) {
    router.push("/admin/products/" + product.RowKey);
}

const deleteProduct = async function(product: Product) {
    await productApi.delete(product);
    data.products = await productApi.retrieveAll();
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Produkte-Liste</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <div class="list-row list-header row">
                        <div class="col-xs-2">Erstellt Am</div>
                        <div class="col-xs-3">Code</div>
                        <div class="col-xs-5">Name</div>
                        <div class="col-xs-2"></div>
                    </div>

                    <div class="list-row row" v-for="product in data.products">
                        <div class="col-xs-2">{{ FormatHelper.stringToDateTime(product.Timestamp!) }}</div>
                        <div class="col-xs-3">{{ product.RowKey }}</div>
                        <div class="col-xs-5">{{ product.Name }}</div>
                        <div class="col-xs-2 list-button-column">
                            <button type="submit" name="createNewProduct" class="buttonstyle" style="height:3rem" @click="editProduct(product)">
                                <span class="">Bearbeiten</span>
                            </button>

                            <button type="submit" name="deleteProduct" class="buttonstyle" style="height:3rem;margin-left: 1rem;" @click="deleteProduct(product)">
                                <span class="">Löschen</span>
                            </button>
                        </div>
                    </div>

                    <button type="submit" name="submit" class="buttonstyle" @click="createNewProduct" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Neues Produkt erstellen</span>
                    </button>
   
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.list-button-column {
    align-content: right;
    display: flex;
    justify-content: right;
}



</style>
