<script setup lang="ts">
import MenuBarTop from './components/MenuBarTop.vue';
import Breadcrumb from './components/Breadcrumb.vue';
import { ref } from '@vue/reactivity';
import UserManager from './sdk/UserManager';
import { useRouter } from 'vue-router';
import UserMenu from './components/UserMenu.vue';

const router = useRouter();
const userManager = new UserManager();
const showMenu = ref(false);

const onUserProfileClick = function() {
  debugger
  showMenu.value = !showMenu.value;
}
</script>

<template>
  <MenuBarTop @userProfileClicked="onUserProfileClick" />
  <div style="position:absolute;top:8rem;left:6rem">
    <Breadcrumb />
  </div>

  <!-- Control Tests -->
  <!-- 
  <div style="margin-top:100px">
    <Checkbox c-id="mycheckbox" v-model="checkBoxVal" text="test of the checkbox" />

    <div>The current value is {{ checkBoxVal  }}</div>
  </div>
  -->

  <RouterView v-slot="{ Component }" :key="$route.fullPath">
  <template v-if="Component">
    <Transition name="fade" mode="out-in">
      <KeepAlive>
        <Suspense>
          <!-- main content -->
          <component :is="Component"></component>

          <!-- loading state -->
          <template #fallback>
            <div class="registration-form">
              <div class="registration-form-body row" style="margin-top:5rem">
                  <div class="col-xs-12">
                      <div class="box-row item-style">
                          <p class="loading-paragraph">Lädt... die Seite wird gleich zur Verfügung stehen.</p>
                      </div>
                  </div>
              </div>
          </div>
          </template>
        </Suspense>
      </KeepAlive>
    </Transition>
  </template>
</RouterView>

  <UserMenu v-model:showMobileMenu="showMenu"></UserMenu>

  <!--<Registration />-->
  <!--<ProductForm />-->
  

</template>

<style>
#app {
  font-family:  Helvetica, Arial, sans-serif;
}

.loading-paragraph {
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.07s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
