<script setup lang="ts">import { reactive } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import EntityApi from '../../sdk/EntityApi';
import Process from '../../sdk/models/Process';
import FormatHelper from '../../sdk/helpers/FormatHelper';

const router = useRouter();  

class ProcessList {
    processes: Process[] = [];
}

const data = reactive(new ProcessList());

const processApi = new EntityApi('processes');
data.processes = await processApi.retrieveAll();

const createNewProcess = function() {
    router.push("/admin/processes/add");
}

const editProcess = function(process: Process) {
    router.push("/admin/processes/" + process.RowKey);
}

const deleteProcess = async function(process: Process) {
    await processApi.delete(process);
    data.processes = await processApi.retrieveAll();
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Prozess-Liste</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <div class="list-row list-header row">
                        <div class="col-xs-2">Erstellt Am</div>
                        <div class="col-xs-3">Code</div>
                        <div class="col-xs-5">Name</div>
                        <div class="col-xs-2"></div>
                    </div>

                    <div class="list-row row" v-for="process in data.processes">
                        <div class="col-xs-2">{{ FormatHelper.stringToDateTime(process.Timestamp!) }}</div>
                        <div class="col-xs-3">{{ process.RowKey }}</div>
                        <div class="col-xs-5">{{ process.Name }}</div>
                        <div class="col-xs-2 list-button-column">
                            <button type="submit" name="createNewProcess" class="buttonstyle" style="height:3rem" @click="editProcess(process)">
                                <span class="">Bearbeiten</span>
                            </button>

                            <button type="submit" name="deleteProcess" class="buttonstyle" style="height:3rem;margin-left: 1rem;" @click="deleteProcess(process)">
                                <span class="">Löschen</span>
                            </button>
                        </div>
                    </div>

                    <button type="submit" name="submit" class="buttonstyle" @click="createNewProcess" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Neuen Prozess erstellen</span>
                    </button>
   
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.list-button-column {
    align-content: right;
    display: flex;
    justify-content: right;
}



</style>
