<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import UserManager from './../sdk/UserManager';
import userProfileSvg from '../assets/user-profile.svg';

//defineProps<{ msg: string }>()
const router = useRouter();

const userManager = new UserManager();
const isLoggedIn = ref(false);
setInterval(checkLoginStatus, 1000);

isLoggedIn.value = userManager.isUserLoggedIn();

function checkLoginStatus()
{
    isLoggedIn.value = userManager.isUserLoggedIn(); 
}

const onClicked = async function() {
    if(isLoggedIn.value === false)
    {
        router.push("/auth/login");
    }
    else {
        await userManager.logout();
        isLoggedIn.value = false;
        router.push({ name: 'Logout' })
    }
}

</script>

<template>
    <img class="user-profile" :class="{ show: isLoggedIn }" :src="userProfileSvg" alt="logo" height="30" width="30" @click="$emit('userProfileClicked')"/>
    <div :class="{ hide: isLoggedIn }" >
        <div style="display: inline;">{{ isLoggedIn ? userManager.getUserName() : '' }}</div>
        <button v-if="$route.name != 'Login'" class="buttonstyle inline-button" @click="onClicked">{{  isLoggedIn ? 'Logout' : 'Login' }}</button>
    </div>
</template>

<style scoped>
    .user-profile
    {
        display: none;
        color: white;
        cursor: pointer;
        margin-right: 0.5rem;
    }
    .inline-button {
        display: inline;
        margin-left: 10px;
    }

    @media only screen and (max-width: 629px) {
        .hide {
            display: none;
        }

        .show{
            display:block;
        }
    }
</style>
