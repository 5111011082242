import { createApp } from 'vue'
import App from './App.vue'
import { useRouter, createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue'
import Admin from './components/Admin.vue'
import ProcessList from './components/processmanagement/ProcessList.vue'
import ProcessForm from './components/processmanagement/ProcessForm.vue'
import ProductList from './components/productmanagement/ProductList.vue'
import ProductForm from './components/productmanagement/ProductForm.vue'
import CourseRegistration from './components/coursemodule/Registration.vue'
import CourseRegistrationConfirmation from './components/coursemodule/RegistrationConfirmation.vue'
import EbookOrderConfirmation from './components/ebooksmodule/EbooksConfirmation.vue'
import RegistrationList from './components/coursemodule/RegistrationList.vue'
import Login from './components/authenticationmodule/Login.vue'
import Logout from './components/authenticationmodule/Logout.vue'
import Register from './components/authenticationmodule/Register.vue'
import EmailTemplateList from './components/emailtemplatemanagement/EmailTemplateList.vue'
import EmailTemplateForm from './components/emailtemplatemanagement/EmailTemplateForm.vue'
import AssetList from './components/assetmanagement/AssetList.vue'
import AssetForm from './components/assetmanagement/AssetForm.vue'
import EbooksDownload from './components/ebooksmodule/EbooksDownload.vue'
import ErrorOccured from './components/ErrorOccured.vue'
import UserManager from './sdk/UserManager'
import CommunityPage from './components/communitymodule/CommunityPage.vue'
import WebHelper from './sdk/helpers/WebHelper'
import TestPage from './components/TestPage.vue'
import UpdateUser from './components/authenticationmodule/UpdateUser.vue'
import LoginList from './components/loginmanagement/LoginList.vue'
import GroupList from './components/groupmanagement/GroupList.vue'
import GroupForm from './components/groupmanagement/GroupForm.vue'

const routes = [
    { 
        name: "MainPage",
        path: '/', component: Home 
    },
    { 
        name: "Admin",
        path: '/admin', 
        component: Admin,
    },
    {
        name: "AdminProcesses",
        path: '/admin/processes', 
        component: ProcessList,
    },
    {
        name: "AdminAddProcesses",
        path: '/admin/processes/add', 
        component: ProcessForm,
    },
    {
        name: "AdminSpecificProcess",
        path: '/admin/processes/:rowKey', 
        component: ProcessForm,
    },
    {
        name: "AdminProducts",
        path: '/admin/products', 
        component: ProductList,
    },
    {
        name: "AdminAddProducts",
        path: '/admin/products/add', 
        component: ProductForm,
    },
    {
        name: "AdminSpecificProduct",
        path: '/admin/products/:rowKey', 
        component: ProductForm,
    },
    {
        name: "AdminRegistrations",
        path: '/admin/registrations', 
        component: RegistrationList,
    },
    {
        name: "AdminSpecificRegistration",
        path: '/kurse/registration/:rowKey',
        component: CourseRegistration
    },
    {
        name: "AdminEmailTemplates",
        path: '/admin/emailtemplates', 
        component: EmailTemplateList,
    },
    {
        name: "AdminAddEmailTemplate",
        path: '/admin/emailtemplates/add', 
        component: EmailTemplateForm,
    },
    {
        name: "AdminSpecificEmailTemplate",
        path: '/admin/emailtemplates/:rowKey', 
        component: EmailTemplateForm,
    },
    {
        name: "AdminAssets",
        path: '/admin/assets', 
        component: AssetList,
    },
    {
        name: "AdminAddAssets",
        path: '/admin/assets/add', 
        component: AssetForm,
    },
    {
        name: "AdminSpecificAsset",
        path: '/admin/assets/:rowKey', 
        component: AssetForm,
    },
    {
        name: "CourseRegistrationConfirmation",
        path: '/kurse/registrationsbestaetigung',
        component: CourseRegistrationConfirmation
    },
    {
        name: 'Login',
        path: '/auth/login',
        component: Login
    },
    {
        name: 'Logout',
        path: '/auth/logout',
        component: Logout
    },
    {
        name: 'Register',
        path: '/auth/register',
        component: Register
    },
    {
        name: 'UpdateUser',
        path: '/auth/update-user',
        component: UpdateUser
    },
    {
        name: "EbookSpecificDownload",
        path: '/ebooks/download/:rowKey',
        component: EbooksDownload
    },
    {
        name: "EbookOrderConfirmation",
        path: '/ebooks/bestellbestaetigung',
        component: EbookOrderConfirmation
    },
    {
        name: "Error",
        path: '/error',
        component: ErrorOccured
    },
    {
        name: 'Community',
        path: '/community',
        component: CommunityPage
    },
    {
        name: 'TestPage',
        path: '/testpage',
        component: TestPage
    },
    {
        name: "AdminLogins",
        path: '/admin/logins', 
        component: LoginList,
    },
    {
        name: "AdminGroups",
        path: '/admin/groups', 
        component: GroupList,
    },
    {
        name: "AdminAddGroups",
        path: '/admin/groups/add', 
        component: GroupForm,
    },
    {
        name: "AdminSpecificGroup",
        path: '/admin/groups/:rowKey', 
        component: GroupForm,
    },
  ]
  
const router = createRouter({
// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
history: createWebHistory(),
routes, // short for `routes: routes`
})

const userManager = new UserManager();

router.beforeEach(async (to, from) => {

    // Store last valid route, so that login can route back to it
    const invalidRoutes = ["Login", "Logout", "Register", "Error", "UpdateUser"];
    if(to.name && !invalidRoutes.includes(to.name.toString())) {
        WebHelper.setStorageValue("lastValidRoute", to.name.toString());
    }
    
    // Reset the scroll behavior in case the modal window was not closed
    document.body.style.overflow = 'auto'
    if (
        to.path.toLowerCase().startsWith('/admin') && // Protected Path
        !userManager.isUserLoggedIn() && // make sure the user is authenticated
        to.name !== 'Login' // Avoid an infinite redirect
    ) {
        return { name: 'Login' } // redirect the user to the login page
    }
  })

  router.afterEach(async (to, from) => {
    if(from.name == 'Login' && to.name == 'Logout') {
        router.back()
    }
  })



const app = createApp(App);

app.use(router);

app.mount('#app');
