import { reactive } from "vue";
import { useRouter } from "vue-router";
import EntityApi from "../../sdk/EntityApi";
import TableEntityBase from "../../sdk/models/TableEntityBase";

export class EntityListControl<EntityType> {

    data = reactive({
        entities: [] as EntityType[]
    });
    
    entityPath:string;
    api:EntityApi;
    router:any;

    constructor(entityPath:string) {
        this.entityPath = entityPath;
        this.api = new EntityApi(this.entityPath);
        this.router = useRouter();
    }
    
    async retrieveAllAsync() {
        this.data.entities = await this.api.retrieveAll();
    }

    createNewEntity() {
        this.router.push("/admin/" + this.entityPath + "/add/");
    }
    
    editEntity(entity: TableEntityBase) {
        this.router.push("/admin/" + this.entityPath + "/" + entity.RowKey);
    }
    
    async deleteEntityAsync(entity: TableEntityBase) {
        await this.api.delete(entity);
        await this.retrieveAllAsync();
    }
}