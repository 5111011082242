import UserProfile from "../components/authenticationmodule/UserProfile";
import AzureFunctionCall from "./AzureFunctionsCall";
import Login from "./models/Login";

export default class AuthenticationApi {

    functionCall: AzureFunctionCall = new AzureFunctionCall();

    async login(login: Login) : Promise<UserProfile> {
        var getResult = await this.functionCall.Post("auth/login", login);
        return await getResult.json();
    }

    async logout() : Promise<UserProfile> {
        var getResult = await this.functionCall.Post("auth/logout", "{}");
        return await getResult.json();
    }

    async register(login: Login) : Promise<UserProfile> {
        var getResult = await this.functionCall.Post("auth/register", login);
        if(getResult.ok) {
            return await getResult.json();
        } else if (getResult.status == 400) {
            var message = await getResult.text();
            throw Error(message);
        } else {
            throw Error("An unexpected error occured, please try again later.");
        }
    }

    async updateUser(login: Login) : Promise<string> {
        var getResult = await this.functionCall.Post("auth/updateuser", login);
        if(getResult.ok) {
            return getResult.text();
        } else if (getResult.status == 400) {
            var message = await getResult.text();
            throw Error(message);
        } else {
            throw Error("An unexpected error occured, please try again later.");
        }
    }
}