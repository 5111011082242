import AuthenticationApi from "./AuthenticationApi";
import WebHelper from "./helpers/WebHelper";
import { GroupRightEnum } from "./models/GroupRightEnum";

const authApi = new AuthenticationApi()

export default class UserManager {
    constructor() {
    }

    async logout() {
        var logoutResult = await authApi.logout();
    }

    isUserLoggedIn() : boolean {
        var userLoginExpires = WebHelper.getCookie("userLoginExpires");
        if(!userLoginExpires) { return false; }

        let expiresUtc = new Date(userLoginExpires);
        const currentUtc = new Date();

        const isLoggedIn = expiresUtc.valueOf() > currentUtc.valueOf();
        console.log('check login status. ExpiresNumber: ' + userLoginExpires + ' Expires: ' + expiresUtc.valueOf() + ' Current: ' + currentUtc.valueOf() + ' isloggedin: ' + isLoggedIn);

        return isLoggedIn;
    }

    getUserName() : string {
        var userName = WebHelper.getCookie("userName");
        return userName as string;
    }

    getUserLogin() : string {
        var user = WebHelper.getCookie("userLogin");
        return user as string;
    }

    getGroupRights() : GroupRightEnum[] {
        var groupRights = WebHelper.getCookie("groupRights");
        var result = [] as GroupRightEnum[];

        groupRights?.split(',').forEach((rightNumberString) => {
            var rightNumber = parseInt(rightNumberString);
            var castedRight = rightNumber as GroupRightEnum;
            result.push(castedRight);
        });

        return result;
    }
}