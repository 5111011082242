<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router';

const route = useRoute();

class CrumbItem {
    Name: string = '';
    Url: string = ''   
}

const crumbs = computed(():CrumbItem[] => {
    if(!route.path.toLowerCase().startsWith('/admin')) { return []; }
    var paths = route.path.split('/').filter(onlyNotEmpty => onlyNotEmpty);

    var result = [];
    for (let i = 0; i < paths.length; i++) {   
        let baseUrl:string = (i == 0) ? '/' : result[i-1].Url + '/';
        var path = paths[i];
        result.push({ Name: path, Url: baseUrl + path })
    }
    return result;
})

</script>

<template>
   <div v-for="(crumb, i) in crumbs" class="link-container">
    <router-link :to="crumb.Url">{{ crumb.Name }}</router-link><span v-if="i < crumbs.length -1" style="padding: 0 1rem;">&gt;</span>
   </div>
</template>

<style scoped>
    .link-container {
        display: inline-block;
    }
</style>