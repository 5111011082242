<script setup lang="ts">import { reactive, ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import EntityApi from '../../sdk/EntityApi';
import FormatHelper from '../../sdk/helpers/FormatHelper';
import Overlay from '../Overlay.vue';
import CommunityTopic from '../../sdk/models/CommunityTopic';
import CommunityCourseItem from '../../sdk/models/CommunityCourseItem';
import CommunityCourseModule from '../../sdk/models/CommunityCourseModule';
import AzureEnvironment from '../../sdk/AzureEnvironment';
import AzureFunctionCall from '../../sdk/AzureFunctionsCall';
import UserManager from '../../sdk/UserManager';
import CommunityMessage from '../../sdk/models/CommunityMessage';
import PageTitle from '../controls/PageTitle.vue';
import speechBubble from '../../assets/speech-bubble.svg';
import arrowDown from '../../assets/arrow-down.svg';
import { GroupRightEnum } from '../../sdk/models/GroupRightEnum';

const router = useRouter();

const activeTab = ref("course");
const showTopicDetailsOverlay = ref(false);
/*
const overlayData = reactive({ registration:null as Registration|null })

data.products = await productApi.retrieveAll();

const registrationApi = new EntityApi('registrations');
data.registrations = await registrationApi.retrieveAll();
*/

const data = reactive({
    topics:[] as CommunityTopic[],
    newTopic: {} as CommunityTopic,

    // All about a specific topic
    selectedTopic: {} as CommunityTopic,
    selectedTopicMessages: [] as CommunityMessage[],
    newMessage: "" as string,

    // All about courses
    courseModules:[] as CommunityCourseModule[],
    selectedCourseItem: null as CommunityCourseItem | null,
    isCourseMenuOpen: false as boolean,
    courseNavigationCollapsed: [] as string[]
});

// Authentication START
const userManager = new UserManager();

// Authentication END

// Topics START
const topicsApi = new EntityApi('CommunityTopics');

const isCreateNewTopicDisabled = ref(false);

const IsNullOrEmpty = function(val : string) {
    return !val || val == '';
}

const createNewTopic = async function() {
    isCreateNewTopicDisabled.value = true;
    try {
        if(IsNullOrEmpty(data.newTopic.Topic) || IsNullOrEmpty(data.newTopic.Message)){
            return;
        }

        let result = await topicsApi.create(data.newTopic);
        if(result.ok) {
            data.newTopic.Topic = '';
            data.newTopic.Message = '';

            await loadAllTopics();
        }
    }
    catch(error) {
        alert('An error occured: ' + error);
    }
    finally {
        isCreateNewTopicDisabled.value = false;
    }
}

const showTopicDetails = async function(topic: CommunityTopic) {
    data.selectedTopic = topic;
    showTopicDetailsOverlay.value = true;

    await loadMessagesForSelectedTopic();
}

const loadAllTopics = async function() {
    data.topics = await topicsApi.retrieveAll();
}
// Topics END


// Messages START
const isCreateNewMessageDisabled = ref(false);
const messagesApi = new EntityApi('CommunityMessages');
const sendMessageToTopic = async function() {
    if(IsNullOrEmpty(data.newMessage)) {
        return;
    }
    const message = {} as CommunityMessage;
    message.PartitionKey = data.selectedTopic.RowKey;
    message.Message = data.newMessage;

    try {
        isCreateNewMessageDisabled.value = true;
        await messagesApi.create(message);
        data.newMessage = '';
        message.Timestamp = new Date(Date.now()).toISOString();
        message.CreatedByName = userManager.getUserName();
        data.selectedTopicMessages.push(message);

        loadAllTopics();
    }
    catch(error) {
        alert('Message could not be sent.')
    }
    finally {
        isCreateNewMessageDisabled.value = false;
    }
}

const loadMessagesForSelectedTopic = async function() {
    data.selectedTopicMessages = await messagesApi.retrieveFiltered("filtertype=partitionkey,partitionkey=" + data.selectedTopic.RowKey);
}

// Messages END


// Courses START
const showCourseTab = function() {
    activeTab.value ='course';
    
    // If no courseitem is selected, the auto-select the first one for desktop. For mobile show the menu to select one.
    if(data.selectedCourseItem == null && data.courseModules.length > 0) {
        if(window.innerWidth > 629) {
            data.selectedCourseItem = data.courseModules[0].Items[0];
        } else {
            data.isCourseMenuOpen = true;
        }
    }
}
const retrieveCourseModules = async function() {
    const courseItemsApi = new EntityApi('CommunityCourseItems');
    const courseItems = await courseItemsApi.retrieveAll();

    let grouped = courseItems.reduce(
    (result:any, currentValue:any) => { 
    (result[currentValue['PartitionKey']] = result[currentValue['PartitionKey']] || []).push(currentValue);
    return result;
    }, {});

    const modules: Array<CommunityCourseModule> = [];
    for (let key in grouped) {
        modules.push({ ModuleName: key, Items: grouped[key] });
    } 

    return modules;
}

const showCourseItemDetails = function(courseItem: CommunityCourseItem) {
    data.selectedCourseItem = courseItem;
    data.isCourseMenuOpen = false;
}

const collapseExpandModule = function(courseModule: CommunityCourseModule) {
    if(!data.courseNavigationCollapsed.includes(courseModule.ModuleName)) {
        data.courseNavigationCollapsed.push(courseModule.ModuleName);
    } else {
        data.courseNavigationCollapsed.splice(data.courseNavigationCollapsed.indexOf(courseModule.ModuleName), 1);
    }
}

const isModuleCollapsed = function(courseModule: CommunityCourseModule) {
    return data.courseNavigationCollapsed.includes(courseModule.ModuleName);
}
// Courses END


// Load initial data
if(userManager.isUserLoggedIn()) {
    data.topics = await topicsApi.retrieveAll();
    data.courseModules = await retrieveCourseModules();
}

showCourseTab();



</script>

<template>
    <div class="form-container">

        <PageTitle text="Happy Homeschooling"></PageTitle>
        <div class="form-container-body row" v-if="userManager.isUserLoggedIn() == false">
            <div class="col-xs-12">
                <div class="cardshadow box-row form-container-style">
                    <p>Du bist aktuell nicht eingeloggt.</p>
                    <div style="padding-top: 2rem;padding-bottom: 3rem;">
                        <button type="submit" name="submit" class="buttonstyle" style="float:left;" @click="router.push({ name: 'Login' })">
                            <span class="">Login</span>
                        </button>
                        <button type="submit" name="submit" class="buttonstyle" style="margin-left: 1rem;float: left;" @click="router.push({ name: 'Register' })">
                            <span class="">Registrieren</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-container-body row" v-if="userManager.isUserLoggedIn()">
            <div class="col-xs-12">

                <div class="cardshadow">

                    <div class="menu">
                        <div class="menu-item" :class="{ 'menu-item-active': activeTab == 'course' }" @click="showCourseTab()"><div><span>Kurs</span></div></div> 
                        <div v-if="userManager.getGroupRights().includes(GroupRightEnum.HappyHomeschoolingForum)" class="menu-item" :class="{ 'menu-item-active': activeTab == 'community' }" @click="activeTab='community'"><div><span>Community</span></div></div>
                        
                    </div>

                    <div class="box-row form-container-style" style="padding-top: 3rem;">
                    
                        <div v-if="activeTab == 'community'">

                            <div v-if="data.topics.length == 0">
                                <label style="color: gray;">Noch keine Themen vorhanden</label>
                            </div>

                            <div v-for="topic in data.topics" class="topic-item" @click="showTopicDetails(topic)">
                                <div class="topic-header">
                                    <div style="display: flex;flex-direction: row;align-items: center;">{{ topic.Topic }}</div>
                                    <div style="font-weight: bold;display: flex;flex-direction: row;align-items: center;"><span style="color: gray;padding-left: 1rem;padding-right: 1rem;"></span><span style="color: gray;font-size: 1.4rem;font-weight: normal;">{{ FormatHelper.stringToDateTime(topic.CreatedOn!) }}</span></div>                                    
                                 </div>
                                <div style="padding: 1rem;white-space: pre-line">{{ topic.Message }}</div>
                                <div class="topic-bottom">
                                    <div style="display: flex;flex-direction: row;align-items: center;">Von {{ topic.CreatedByName }}<span style="color: gray;padding-left: 1rem;padding-right: 1rem;">•</span><img class="user-profile" :src="speechBubble" alt="Kommentare" height="24" width="16" @click="$emit('userProfileClicked')"/><span style="padding-left: 0.5rem;">{{ topic.NumberOfMessages }}</span></div>                                    
                                 </div>
                            </div>

                            <div style="margin-top: 3rem;font-size: 16px;font-weight: bold;">Neuen Beitrag erstellen</div>
                            <input type="text" name="topicName" id="topicName" class="textbox-style" placeholder="Titel" v-model="data.newTopic.Topic">
                            <textarea type="text" rows="4" name="productDescription" id="productDescription" class="textarea-style" placeholder="Kommentar einfügen" style="margin-top:1rem"  v-model="data.newTopic.Message"></textarea>
                            
                            <div style="display: flex;">
                                <button type="submit" name="submit" class="buttonstyle" style="margin-top:1rem;" @click="createNewTopic" :disabled="isCreateNewTopicDisabled">
                                    <span class="">Hinzufügen</span>
                                </button>
                            </div>
                        </div>

                        <div v-if="activeTab == 'course'">
                            <h2 style="margin-bottom: 2rem;font-size: min(max(16px, 5vw), 24px)"><span class="burger-button show-mobile" style="padding-right: 2rem;" @click="data.isCourseMenuOpen = !data.isCourseMenuOpen">☰</span>Übersicht</h2>
                            

                                    
                                    <div class="content-wrapper">
                                        <div class="content-left" :class="{ 'show-menu': data.isCourseMenuOpen }">
                                            <div v-for="courseModule in data.courseModules">
                                                <div style="margin-bottom: 2rem;margin-right: 2rem;">
                                                    <div @click="collapseExpandModule(courseModule)">
                                                        <div class="module-title">
                                                            <div style="width: 3rem;">
                                                                <div v-if="isModuleCollapsed(courseModule)" style="display: flex;align-items: center;height: 100%;"><img class="user-profile" style="transform: rotate(-90deg);margin-left: -3px;" :src="arrowDown" alt="closed" width="20"/></div>
                                                                <div v-else style="display: flex;align-items: center;height: 100%;"><img class="user-profile" style="margin-left: -3px;" :src="arrowDown" alt="open" width="20"/></div>
                                                            </div>                                                 
                                                            <h3>{{ courseModule.ModuleName }}</h3>
                                                        </div>                                                    
                                                    </div>
                                                    <div v-if="!isModuleCollapsed(courseModule)" v-for="courseItem in courseModule.Items" @click="showCourseItemDetails(courseItem)">
                                                        <span class="course-menu-item" :class="{ 'course-menu-item-selected': data.selectedCourseItem == courseItem }">{{ courseItem.Title }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-right" :class="{ 'hide-content': data.isCourseMenuOpen }">
                                            <h3 style="padding-bottom: 2rem;">{{ data.selectedCourseItem?.Title }}</h3>

                                            <video v-if="data.selectedCourseItem?.ContentType == 'mp4'" style="width: 100%;max-width: 600px; border: 1px solid gray;" controls="false" :key="data.selectedCourseItem?.ContentUrl">
                                                <source :src="data.selectedCourseItem?.ContentUrl" type="video/mp4" />
                                            </video>

                                            <a v-if="data.selectedCourseItem?.ContentType == 'pdf'" :href="AzureFunctionCall.GetAzureFunctionUrl() + data.selectedCourseItem?.ContentUrl" class="buttonstyle" style="width: fit-content;">PDF herunterladen</a>


                                        </div>
                                    </div>

                                
                        </div>
                    </div>
                </div>
                


                
            </div>
        </div>

        <Overlay v-model:isvisible="showTopicDetailsOverlay" height="70%" width="70%" rounded-corners="0.5rem">
            <div style="height: 100%;background-color: var(--secondary-background-color);">

            <div class="topic-header topic-header-overlay">
                <div class=" ">{{ data.selectedTopic.Topic }}</div>
                <div style="width: 3rem;text-align: center;cursor: pointer;" @click="showTopicDetailsOverlay = false">X</div>
            </div>

            <div class="message-container">
                
                <div class="topic-message">{{ data.selectedTopic.Message }}</div>
                <hr style="border:none; border-bottom:1px solid lightgray;margin-top: 1.5rem;margin-bottom: 1.5rem;" />

                <div v-if="data.selectedTopicMessages.length == 0" style="color: gray;">
                    <label>Noch keine Antworten vorhanden.</label>
                </div>
                
                <div v-for="topicMessage in data.selectedTopicMessages" class="message-item">
                    <div style="font-size: 1.5rem;font-weight: bold;">{{ topicMessage.CreatedByName ? topicMessage.CreatedByName : 'Anonymous'  }}<span style="color: gray;padding-left: 1.5rem;padding-right: 1.5rem;">•</span><span style="color: gray;font-weight: normal;">{{ FormatHelper.stringToDateTime(topicMessage.Timestamp!) }}</span></div>
                    <div style="padding-top: 1rem;">{{ topicMessage.Message }}</div>
                </div>

                <hr style="border:none; border-bottom:1px solid lightgray;margin-top: 1.5rem;margin-bottom: 1.5rem;" />

                <textarea v-model="data.newMessage" type="text" rows="4" name="topicComment" id="topicComment" class="textarea-style" style="border-radius: 1rem;" placeholder="Dein Kommentar" ></textarea>

                <button type="submit" name="submit" class="buttonstyle" style="margin-top:1rem;" @click="sendMessageToTopic" :disabled="isCreateNewMessageDisabled">
                    <span class="">Senden</span>
                </button>
            </div>
        </div>
        </Overlay>
    </div>
</template>

<style scoped>

.module-title {
    display: flex;
    cursor: pointer;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 629px) {
    .module-title {
        margin-top: 1rem;
        
    }
}
.overlay-box {
    background-color: var(--secondary-background-color) !important;
}
.message-container {
    padding: 2rem;
    background-color: var(--secondary-background-color);
}
.message-item {
    border: 1px solid lightgray;
    background-color: rgba(187, 234, 121, 0.05);
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 1rem;
    white-space: pre-line;
}
.topic-header, .topic-bottom {
    padding: 1rem;
    padding-top:0.5rem; 
    padding-bottom:0.5rem;
    display:flex;
    justify-content: space-between;
}
.topic-header {
    background-color: var(--secondary-color);
    border-bottom: 1px solid silver;
    font-weight: bold;
}

.topic-bottom {
    border-top: 1px solid silver;
    background-color: var(--background-color);
    color: gray;
    font-size: 1.4rem;
    font-weight: normal;
}

@media only screen and (max-width: 629px) {
    .topic-header {
        font-size: 1.2rem;
    }
}

.topic-header-overlay {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.topic-message {
    white-space: pre-line;
}
.topic-item {
    border: 1px solid #c6c6c6;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.topic-item:hover {
    border-color: grey;
    cursor: pointer;
}
.menu {
    background-color: var(--secondary-background-color);
    padding-left: 2rem;
    display: flex;
}
.menu-item {
    font-size: 16px;
    font-weight: bold;
    padding-right: 10px;
    cursor: pointer;
    
}
.menu-item:not(:first-child) {
    padding-left: 10px;
}

.menu-item > div {
    height: 5rem;
    display: flex;
    align-items: end;
    padding: 0.5rem;
    border-bottom: 3px solid var(--secondary-background-color);
}

.menu-item-active > div {
    border-bottom: 3px solid var(--main-color);
}

.menu-item:hover > div {
    color:grey;
}
.list-row div[class^="col-"] {
    display: flex;
    align-items: center;
}

.list-button-column {
    align-content: right;
    display: flex;
    justify-content: right;
}

.burger-button {
    display: none;
    cursor: pointer;

    &:hover {
        color: gray;
    }
}

*{
	box-sizing: border-box;
}
.content-wrapper {
    display:flex;
}
.content-left {
	width:360px
}
.content-right {
    flex: 1;
}

@media only screen and (max-width: 629px) {
 .content-left {
    display: none;
  }
.content-right {
    flex: 1;
  }
  
  .show-menu {
  	display: block;
    background-color: var(--secondary-background-color);
    width: 100%;
  }

  .hide-content {
  	display: none;
  }

  .show-mobile {
    display:inline;
  }
}

.course-menu-item {
    padding-left: 3rem;
    cursor: pointer;
    line-height: 3rem;

    &:hover {
        color: darkgray;
    }
}

@media only screen and (max-width: 380px) {
    .course-menu-item {
        padding-left: 0rem;
    }
}

@media only screen and (max-width: 629px) {
    .course-menu-item {
        font-size: 14px;
        line-height: 4rem;
    }
}

.course-menu-item-selected {
    font-weight: bold;
}

.button-pdf-download {
  font: bold 11px Arial;
  text-decoration: none;
  background-color: #EEEEEE;
  color: #333333;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #CCCCCC;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #CCCCCC;
}


</style>
