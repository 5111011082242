export default class WebHelper {
    static getCookie(name: string): string|null {
        const nameLenPlus = (name.length + 1);
        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
    }

    static setStorageValue(key: string, value: string) {
        if (typeof(Storage) !== "undefined") {
            localStorage.setItem(key, value);
        }
    }

    static getStorageValue(key:string) : string|null {
        if (typeof(Storage) !== "undefined") {
            return localStorage.getItem(key);
        }
        else {
            return null;
        }
    }
} 