<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref } from 'vue'
import Login from '../../sdk/models/Login'
import AuthenticationApi from '../../sdk/AuthenticationApi'
import Overlay from '../Overlay.vue';
import WebHelper from '../../sdk/helpers/WebHelper';
import PageTitle from '../controls/PageTitle.vue';

const router = useRouter();
const route = useRoute();

class FormValidation {
    EmailValid: boolean|null = null;
}

const authApi = new AuthenticationApi()
const data = reactive({ 
  login: new Login(),
  formValidatione: new FormValidation(),
  registrationInProgress: false,
  registrationErrorOccured: false,
})

const validateEmail = function() {
    data.formValidatione.EmailValid = data.login.User != null && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(data.login.User);
}

const registerClicked = async function() {
    validateEmail();

    if(data.formValidatione.EmailValid === true && data.formValidatione.EmailValid === true) {
    data.login.Type = "userpass";
    try {
        data.registrationErrorOccured = false;
        data.registrationInProgress = true;
        var registerResult = await authApi.register(data.login);
        var loginResult = await authApi.login(data.login);
    }
    catch(error) {
      data.registrationInProgress = false;
      data.registrationErrorOccured = true;
      return;
    }

    try {

        try {
          const lastValidRoute = WebHelper.getStorageValue("lastValidRoute");
          if(lastValidRoute != null) {
            router.push({ name: lastValidRoute });
          } else {
            router.back();
          }
        }
        catch {
          router.push("/");
        }

    }
    catch(error: any) {
        alert(error.message);
    }
  }
}

</script>

<template>
  <div class="form-container">

    <PageTitle text="Registration"></PageTitle>

    <div class="row">
      <div class="col-xs-12">
        <div class="box-row card cardshadow">
            <label for="name" class="label-style">Benutzername</label>
            <input type="text" name="name" id="name" class="textbox-style" v-model="data.login.Name">

            <label for="user" class="label-style">Email</label>
            <input type="text" name="user" id="user" class="textbox-style" v-model="data.login.User">
            <label v-if="data.formValidatione.EmailValid === false" for="email" class="label-style label-warning">Bitte eine korrekte Emailadresse eingeben.</label>

            <label for="productName" class="label-style">Passwort</label>
            <input type="password" name="productName" id="productName" class="textbox-style" v-model="data.login.Password">

            <button type="submit" name="submit" class="buttonstyle" style="margin-top:3rem; margin-bottom: 1rem;" @click="registerClicked">
                <span class="">Registrieren</span>
            </button>
            <label v-if="data.registrationErrorOccured" class="label-style label-warning">Ein Fehler ist augetreten. Bitte später nochmals probieren.</label>
        </div>
      </div>
    </div>

    <Overlay v-model:isvisible="data.registrationInProgress" :is-closable="false" height="80px" width="200px" rounded-corners="0.5rem">
        <div style="display: flex;justify-content: center;align-items: center;height: 100%;"><div style="color: gray;">Registrierung l&auml;uft...</div></div>
    </Overlay>
  </div>
</template>

<style scoped>
.card {
  background-color: white;
    padding: 2rem
}

</style>
