
<script setup lang="ts">import { onActivated, reactive, ref } from 'vue';
import Registration from '../../sdk/models/Registration';
import Process from '../../sdk/models/Process';
import ProcessInstance from '../../sdk/models/ProcessInstance';
import ProcessApi from '../../sdk/ProcessApi';
import FormatHelper from '../../sdk/helpers/FormatHelper';
import ProcessActivityLogVue from '../../components/processmanagement/ProcessActivityLog.vue';

class DataObject {
    processes: Process[] = [];
    processInstances: ProcessInstance[] = [];
    activeProcessInstanceDetails: ProcessInstance|null = null;
}

const props = defineProps({
  registration: Registration
})

const data = reactive(new DataObject());

const processApi = new ProcessApi();
data.processes = await processApi.retrieveAllProcesses();
data.processInstances = await processApi.retrieveProcessInstancesByRegistration(props.registration?.RowKey!);

const startingProcess = ref(null as Process|null) 
const startProcess = async function(process: Process, registration: Registration) {
    startingProcess.value = process;
    const startProcessParameter = { ProcessRowKey: process.RowKey!, RegistrationRowKey: registration.RowKey! }
    await processApi.startProcess(startProcessParameter);
    data.processInstances = await processApi.retrieveProcessInstancesByRegistration(props.registration?.RowKey!);
    startingProcess.value = null;
}

const deleteProcessInstance = async function(processInstance: ProcessInstance) {
    await processApi.deleteProcessInstance(processInstance);
    data.processInstances = await processApi.retrieveProcessInstancesByRegistration(props.registration?.RowKey!);
}

const getProcessNameByProcessRowKey = function(processRowKey:string) :string {
    var processesFound = data.processes.filter(process => process.RowKey == processRowKey);
    if(processesFound.length != 1) {
        return "Prozess nicht gefunden";
    } else {
        return processesFound[0].Name!;
    }
}

const showProcessInstanceDetail = async function(processInstance:ProcessInstance) {
    if(data.activeProcessInstanceDetails == processInstance) {
        data.activeProcessInstanceDetails = null;
    } else {
        processInstance.ProcessActivityLogs = await processApi.retrieveActiviyLogsByProcessInstance(processInstance.RowKey);
        data.activeProcessInstanceDetails = processInstance;
    }
}

onActivated(async () => {
});

</script>

<template>

<div class="overlay-content">
    <h3>Prozesse verwalten für {{ registration?.Name }}</h3>
    
    <div class="form-container-body row">
        <div class="col-xs-12">
            <div class="box-row">
                <div class="list-row list-header row">
                    <div class="col-xs-9">Prozess-Name</div>
                    <div class="col-xs-3"></div>
                </div>

                <div class="list-row row" v-for="process in data.processes">
                    <div class="col-xs-9">{{ process.Name }}</div>
                    <div class="col-xs-3 list-button-column">
                        <button type="submit" name="startProcess" class="buttonstyle" style="height:3rem" @click="startProcess(process, registration!)" :disabled="startingProcess?.RowKey == process.RowKey">
                            <span class="" title="Startet den Process">{{ startingProcess?.RowKey === process.RowKey ? 'Startet...' : 'Prozess Starten'}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--<div>{{ registration?.PartitionKey }} {{ registration?.RowKey }}</div>-->

    <h3 style="margin-top:2rem">Laufende Prozesse</h3>

    <div class="form-container-body row">
        <div class="col-xs-12">
            <div class="box-row">
                <div class="list-row list-header row">
                    <div class="col-xs-5">Prozess-Name</div>
                    <div class="col-xs-3">Gestartet Am</div>
                    <div class="col-xs-3">Gestoppt Am</div>
                    <div class="col-xs-1"></div>
                </div>

                <div class="list-row row" v-for="processInstance in data.processInstances">                 
                    <div class="col-xs-5">
                            <div style="width:20px;text-align: center;font-weight: bold;" @click="showProcessInstanceDetail(processInstance)">{{ data.activeProcessInstanceDetails != processInstance ? '+' : '-' }}</div>
                            <span>{{ getProcessNameByProcessRowKey(processInstance.ProcessRowKey!) }}</span></div>
                    <div class="col-xs-3">{{ FormatHelper.stringToDateTime(processInstance.Started!) }}</div>
                    <div class="col-xs-3">{{ FormatHelper.stringToDateTime(processInstance.Ended!) }}</div>
                    <div class="col-xs-1 list-button-column">
                        <button type="submit" name="deleteProcessInstance" class="buttonstyle" style="height:3rem" @click="deleteProcessInstance(processInstance)">
                            <span class="" title="Prozess-Instanz löschen">&#x2718;</span>
                        </button>
                    </div>
                    <div class="col-xs-12" v-if="data.activeProcessInstanceDetails == processInstance">
                        <div style="padding-left: 20px;width:100%">
                            <ProcessActivityLogVue v-for="activityLog in processInstance.ProcessActivityLogs"  :logitem="activityLog"></ProcessActivityLogVue>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <button name="submit" class="buttonstyle" @click="$emit('closeOverlay')" style="margin-top:3rem; margin-bottom: 1rem;">
            <span >Close</span>
        </button>
    </div>
    
</div>

</template>

<style scoped>

    .overlay-content {
        padding: 3rem;
    }

    .list-button-column {
        justify-content: end;
    }

</style>
