 export default class FormatHelper {
    static stringToDateTime(timestamp:string|null|undefined):string {
        if(!timestamp) { return ''; }
        var date = new Date(timestamp);
        const options = {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'} as Intl.DateTimeFormatOptions;
        return date.toLocaleString('de-CH', options); 
    }

    static stringToDate(timestamp:string|null|undefined):string {
        if(!timestamp) { return ''; }
        var date = new Date(timestamp);
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'} as Intl.DateTimeFormatOptions;
        return date.toLocaleString('de-CH', options); 
    }
}