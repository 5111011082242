<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref } from 'vue'
import Login from '../../sdk/models/Login'
import AuthenticationApi from '../../sdk/AuthenticationApi'
import Overlay from '../Overlay.vue';
import userManager from '../../sdk/UserManager';
import WebHelper from '../../sdk/helpers/WebHelper';

const router = useRouter();
const route = useRoute();
const usermgr = new userManager();

const authApi = new AuthenticationApi()
const data = reactive({ 
  login: new Login(),
  updateUserInProgress: false,
  updateUserErrorOccured: false,
})

data.login.Name = usermgr.getUserName();

const saveClicked = async function() {
    try {
        data.updateUserErrorOccured = false;
        data.updateUserInProgress = true;
        await authApi.updateUser(data.login);
    }
    catch(error) {
      data.updateUserInProgress = false;
      data.updateUserErrorOccured = true;
      return;
    }

    try {

        try {
          const lastValidRoute = WebHelper.getStorageValue("lastValidRoute");
          if(lastValidRoute != null) {
            router.push({ name: lastValidRoute });
          } else {
            router.back();
          }
        }
        catch {
          router.push("/");
        }

    }
    catch(error: any) {
        alert(error.message);
    }
}

</script>

<template>
  <div class="form-container">
    <h3 style="margin-bottom:2rem">Benutzerkonto Anpassen</h3>

    <div class="row">
      <div class="col-xs-12">
        <div class="box-row card cardshadow">
            <label for="name" class="label-style">Benutzername</label>
            <input type="text" name="name" id="name" class="textbox-style" v-model="data.login.Name">

            <button type="submit" name="submit" class="buttonstyle" style="margin-top:3rem; margin-bottom: 1rem;" @click="saveClicked">
                <span class="">Speichern</span>
            </button>
            <label v-if="data.updateUserErrorOccured" class="label-style label-warning">Ein Fehler ist augetreten. Bitte später nochmals probieren.</label>
        </div>
      </div>
    </div>

    <Overlay v-model:isvisible="data.updateUserInProgress" :is-closable="false" height="80px" width="200px" rounded-corners="0.5rem">
        <div style="display: flex;justify-content: center;align-items: center;height: 100%;"><div style="color: gray;">Speichert...</div></div>
    </Overlay>
  </div>
</template>

<style scoped>
.card {
  background-color: white;
    padding: 2rem
}

</style>
