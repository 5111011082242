import AzureFunctionCall from "./AzureFunctionsCall";

export default class EntityApi {

    functionCall: AzureFunctionCall = new AzureFunctionCall();

    path:string = '';

    constructor(entityPath:string) {
        this.path = entityPath;
    }

    async retrieveAll() : Promise<any[]> {
        var getResult = await this.functionCall.Get(this.path);
        return await getResult.json();
    }

    async retrieveFiltered(filter:string) : Promise<any[]> {
        var getResult = await this.functionCall.Get(this.path + "/" + filter);
        return await getResult.json();
    }

    async retrieve(rowKey: string) : Promise<any> {
        var getResult = await this.functionCall.Get(this.path + "/" + rowKey);
        return await getResult.json();
    }

    async create(entity:any): Promise<Response> {
        if(!entity.PartitionKey) {
            entity.PartitionKey = 'system';
        }
        return await this.functionCall.Post(this.path, entity);
    }

    async update(entity:any) {
        var updateResult = await this.functionCall.Put(this.path, entity);
    }

    async delete(entity:any) {
        var deleteResult = await this.functionCall.Delete(this.path, entity);
    }
} 