<script setup lang="ts">
import { onActivated } from 'vue'
import { useRoute } from 'vue-router'
import AzureEnvironment from '../../sdk/AzureEnvironment';
import { EntityFormControl } from '../entityform/EntityFormControl';

const formControl = new EntityFormControl('products');

const route = useRoute();

onActivated(async () => {
    await formControl.initializeAsync(route.params.rowKey as string);
});

const getProductUrl = function() {
    const productCode = formControl.data.entity?.RowKey ? formControl.data.entity.RowKey : "";
    if(formControl.data.entity.PartitionKey == 'courses') {
        return AzureEnvironment.GetAzureBaseUrl(false) + 'kurse/registration/' + productCode;
    } else {
        return AzureEnvironment.GetAzureBaseUrl(false) + 'ebooks/download/' + productCode;
    }
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Produkt Erfassung</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    

                    <label for="productCategory" class="label-style">Kategorie</label>
                    <select name="productCategory" v-model="formControl.data.entity.PartitionKey">
                        <option value="products">Produkt</option>
                        <option value="courses">Kurs</option>
                        <option value="ebooks">Ebooks</option>
                    </select>

                    <label for="productCode" class="label-style">Produkt-Code</label>
                    <input type="text" name="productCode" id="productCode" class="textbox-style" v-model="formControl.data.entity.RowKey" :disabled="formControl.data.isCreate == false">
                    <label for="productName" class="label-style">Produkt-Name</label>
                    <input type="text" name="productName" id="productName" class="textbox-style" v-model="formControl.data.entity.Name">
                    <label for="productPrice" class="label-style">Preis in CHF/EUR</label>
                    <input type="text" name="productPrice" id="productPrice" class="textbox-style" v-model="formControl.data.entity.Price">
                    <label for="productDescription" class="label-style">Beschreibung</label>
                    <textarea type="text" rows="4" name="productDescription" id="productDescription" class="textarea-style" v-model="formControl.data.entity.Description"></textarea>

                    <div v-if="formControl.data.entity.PartitionKey == 'courses'">
                        <label for="productStartDate" class="label-style">Start-Datum</label>
                        <input type="text" name="productStartDate" id="productStartDate" class="textbox-style" v-model="formControl.data.entity.StartDate">

                        <label for="productEndeDate" class="label-style">End-Datum</label>
                        <input type="text" name="productEndeDate" id="productEndeDate" class="textbox-style" v-model="formControl.data.entity.EndDate">

                        <label for="registrationUrl" class="label-style">Registrations-Link</label>
                        <input type="text" name="registrationUrl" id="registrationUrl" class="textbox-style" :value="getProductUrl()" disabled>

                    </div>

                    <div v-if="formControl.data.entity.PartitionKey == 'ebooks'">
                        <label for="asset" class="label-style">Asset-Code</label>
                        <input type="text" name="asset" id="asset" class="textbox-style" v-model="formControl.data.entity.AssetCode">

                        <label for="ebooksUrl" class="label-style">Ebooks-Link</label>
                        <input type="text" name="ebooksUrl" id="ebooksUrl" class="textbox-style" :value="getProductUrl()" disabled>
                    </div>
                    
                    <div style="display: flex;">
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.cancel" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Abbrechen</span>
                        </button>
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.saveEntity" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span class="">Speichern</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>


</template>

<style scoped>

    div[class^="col-"] {
        margin-bottom: 1rem;
    }

    .label-style {
        font-weight: 400;
        line-height: 1.5;
        font-family: Roboto,sans-serif;
        color: #323335;
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }    

</style>
