export default class AzureEnvironment {

    public static GetAzureBaseUrl(apiUrl:boolean) {
        if(window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1") {
            if(apiUrl) {
                return  "http://localhost:7071/";
            } else {
                return "http://127.0.0.1:5173/";
            }
        } else {
            return "https://app.corinawidmer.com/";
        }
    }
}