<script setup lang="ts">
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import Registration from '../../sdk/models/Registration';
import Course from '../../sdk/models/Product';
import EntityApi from '../../sdk/EntityApi';
import Checkbox from '../controls/Checkbox.vue';

const route = useRoute();
const router = useRouter();
const productApi = new EntityApi('products');
const registrationApi = new EntityApi('registrations');
class FormValidation {
    NameValid: boolean|null = null;
    EmailValid: boolean|null = null;
}
const data = reactive({ 
    registration: new Registration(),
    product: null as Course|null,
    formValidatione: new FormValidation()
})

const rowKey = route.params.rowKey as string;
data.product = await productApi.retrieve(rowKey);
data.registration.PartitionKey = data.product?.PartitionKey + "^" + data.product?.RowKey + "^" + data.product?.StartDate;

const validateName = function() {
    data.formValidatione.NameValid = data.registration.Name != null && data.registration.Name.length > 0;
}

const validateEmail = function() {
    data.formValidatione.EmailValid = data.registration.Email != null && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(data.registration.Email);
}

const registerCourse = async function(e:any) {
    validateName();
    validateEmail();

    if(data.formValidatione.NameValid === true && data.formValidatione.EmailValid === true) {
        var result = await registrationApi.create(data.registration);
        if(result.ok) {
            router.push("/ebooks/bestellbestaetigung");
        } else {
            router.push("/error");
        }
    }
    
    e.preventDefault();
}

</script>

<template>
    <div class="registration-form">
        <h3>Über das Ebook</h3>
        <div class="registration-form-body row">
            <div class="col-xs-12">
                <div class="box-row item-style cardshadow">                    

                    <div class="course-description-row">
                        <div class="course-description-label">Name</div>
                        <div>{{ data.product?.Name }}</div>
                    </div>
                    <div class="course-description-row">
                        <div class="course-description-label">Beschreibung</div>
                        <div style="white-space: pre-wrap;">{{ data.product?.Description}}</div>
                    </div>
                    <div v-if="data.product?.Price" class="course-description-row">
                        <div class="course-description-label">Preis</div>
                        <div>{{ data.product?.Price }} CHF/EUR</div>
                    </div>

                    <h3 class="form-body-title">Bestellung f&uuml;r</h3>
                    <label for="senderName" class="label-style">Name</label>
                    <input type="text" name="senderName" id="senderName" class="textbox-style" autocomplete="name" v-model="data.registration.Name">
                    <label v-if="data.formValidatione.NameValid === false" for="senderName" class="label-style label-warning">Bitte Name eingeben.</label>

                    <label for="email" class="label-style">Email</label>
                    <input type="text" name="email" id="email" class="textbox-style" autocomplete="email" v-model="data.registration.Email">
                    <label v-if="data.formValidatione.EmailValid === false" for="email" class="label-style label-warning">Bitte Emailadresse eingeben.</label>

                    <label for="comment" class="label-style">Kommentar (optional)</label>
                    <textarea type="text" rows="4" name="comment" id="comment" class="textarea-style" v-model="data.registration.Comment"></textarea>                    

                    <Checkbox c-id="allowemails" v-model="data.registration.AllowEmails" text="Ich möchte gerne Emails zu neuen Kursen und Produkten erhalten." />
                    
                    <button type="submit" name="submit" class="buttonstyle" style="margin-top:3rem; margin-bottom: 1rem;" @click="registerCourse">
                        <span class="">Absenden</span>
                    </button>
                </div>
            </div>
        </div>
        
        
    </div>


</template>

<style scoped>

    .course-description-row {
        display:flex;
        line-height: 4rem;
    }
    .course-description-label {
        width:14rem;
    }

    div[class^="col-"] {
        margin-bottom: 1rem;
    }

    .form-body-title {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    .label-style {
        font-weight: 400;
        line-height: 1.5;
        font-family: Roboto,sans-serif;
        color: #323335;
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }

      .label-warning {
          color: red;
          font-size: 1.4rem;
      }

    .textbox-style {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;
        border: 1px solid #c6c6c6;
        border-radius: 5px;
        color: #323335;
        font-size: 16px;
        font-family: inherit;
        line-height: 1.25;
        background-color: #fff;
        box-shadow: none;
        height: 50px;
    }

    

</style>
