<script setup lang="ts">import { useRouter } from 'vue-router';

const router = useRouter();

const routeTo = function(route:string) {
  router.push(route);
}
</script>

<template>
  <div class="form-container">
    <h3 style="margin-bottom:2rem">Aministration</h3>

    <div class="row">
      <div class="col-xs-4">
          <div class="box-row" @click="routeTo('/admin/registrations/')">
            <div class="card cardshadow">
              <h4>Registrationen</h4>
            </div>
          </div>
      </div>
      <div class="col-xs-4" @click="routeTo('/admin/products/')">
            <div class="box-row card cardshadow">
              <h4>Produkte</h4>
            </div>
      </div>
      
      <div class="col-xs-4">
          <div class="box-row card cardshadow" @click="routeTo('/admin/assets/')">
            <h4>Assets</h4>
          </div>
      </div>

      

      

    </div>

    <h3 style="margin-bottom:2rem">Berechtigungen</h3>

    <div class="row">
      
      <div class="col-xs-4" @click="routeTo('/admin/logins/')">
            <div class="box-row card cardshadow">
              <h4>Benutzer</h4>
            </div>
      </div>

      <div class="col-xs-4" @click="routeTo('/admin/groups/')">
            <div class="box-row card cardshadow">
              <h4>Gruppen</h4>
            </div>
      </div>
    </div>

    <h3 style="margin-bottom:2rem">Einstellungen</h3>

    <div class="row">
      <div class="col-xs-4">
          <div class="box-row card cardshadow" @click="routeTo('/admin/emailtemplates/')">
            <h4>Email-Templates</h4>
          </div>
      </div>
      
      <div class="col-xs-4">
          <div class="box-row card cardshadow" @click="routeTo('/admin/processes/')">
            <h4>Prozesse</h4>
          </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 12rem;
  cursor:pointer;
}

div[class^="col-"] {
    margin-bottom: 2rem;
}

.card:hover {
  background-color: honeydew;
}

</style>
