<script setup lang="ts">
import { reactive } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import EntityApi from '../../sdk/EntityApi';
import Login from '../../sdk/models/Login';

const router = useRouter();  

class LoginList {
    products: Login[] = [];
}

const data = reactive(new LoginList());

const loginApi = new EntityApi('logins');
data.products = await loginApi.retrieveAll();

const deleteProduct = async function(product: Login) {
    await loginApi.delete(product);
    data.products = await loginApi.retrieveAll();
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Logins</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <div class="list-row list-header row">
                        <div class="col-xs-2">Erstellt Am</div>
                        <div class="col-xs-3">User</div>
                        <div class="col-xs-5">Name</div>
                        <div class="col-xs-2"></div>
                    </div>

                    <div class="list-row row" v-for="product in data.products">
                        <!--<div class="col-xs-2">{{ FormatHelper.stringToDateTime(product.Timestamp!) }}</div>-->
                        <div class="col-xs-3">{{ product.User }}</div>
                        <div class="col-xs-5">{{ product.Name }}</div>
                        <div class="col-xs-2 list-button-column">
                            <button type="submit" name="deleteProduct" class="buttonstyle" style="height:3rem;margin-left: 1rem;" @click="deleteProduct(product)">
                                <span class="">Löschen</span>
                            </button>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.list-button-column {
    align-content: right;
    display: flex;
    justify-content: right;
}



</style>
