import AzureFunctionCall from "./AzureFunctionsCall";
import EntityApi from "./EntityApi";
import ProcessInstance from "./models/ProcessInstance";
import ProcessStart from "./models/ProcessStart";

export default class ProcessApi {

    functionCall: AzureFunctionCall = new AzureFunctionCall();

    async retrieveAllProcesses() {
        const processApi = new EntityApi('processes');
        return await processApi.retrieveAll();
    }    

    async startProcess(processStart: ProcessStart) {
        var getResult = await this.functionCall.Post("process/start", processStart);
        return await getResult.json();
    }

    async retrieveProcessInstancesByRegistration(registrationRowKey:String|null) : Promise<any[]> {
        var getResult = await this.functionCall.Get("processinstancesfiltered/" + "filtertype=registrationrowkey,rowkey=" + registrationRowKey);
        return await getResult.json();
    }

    async retrieveActiviyLogsByProcessInstance(processInstanceRowKey:String|null) : Promise<any> {
        var getResult = await this.functionCall.Get("processactivitylogsfiltered/" + "filtertype=processinstancerowkey,rowkey=" + processInstanceRowKey);
        return await getResult.json();
    }

    async deleteProcessInstance(processInstance:ProcessInstance) : Promise<void> {
        var processInstanceApi = new EntityApi("processinstances");
        return await processInstanceApi.delete(processInstance);
    }
} 