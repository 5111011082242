<script setup lang="ts">
const props = defineProps({
    cId: String,
    modelValue: Boolean,
    text: String,
})

const emit = defineEmits(['update:modelValue'])

const checkBoxSet = function(event:any) {
    emit('update:modelValue', event.target.checked);
}

</script>

<template>
    <label :for="cId" class="checkbox-container" style="margin-top:1rem">{{ text }}
        <input type="checkbox" :name="cId" :id="cId" :checked="modelValue" @change="checkBoxSet" >
        <span class="checkmark"></span>
    </label>
</template>

<style scoped>
    

    div[class^="col-"] {
        margin-bottom: 1rem;
    }

    .thankyou-title {
        padding-top: 2rem;
        padding-bottom: 1rem;
        text-align: center;
    }

    .thankyou-paragraph {
        text-align: center;
        margin-bottom: 2rem;
    }

</style>
