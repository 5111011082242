
<script setup lang="ts">import { onActivated } from 'vue';
import ProcessActivityLog from '../../sdk/models/ProcessActivityLog';
import FormatHelper from '../../sdk/helpers/FormatHelper';

const props = defineProps({
  logitem: ProcessActivityLog,
})

onActivated(async () => {
    
});

</script>

<template>
<div class="row activitylogitem">                 
    <div class="col-xs-3">{{ FormatHelper.stringToDateTime(logitem?.Started) }}</div>
    <div class="col-xs-3">{{ logitem?.ActivityType }}</div>
    <div class="col-xs-3">{{ logitem?.ActivityName }}</div>
    <div class="col-xs-3">Successful: {{ logitem?.Successful }}</div>
    <div class="col-xs-12" v-if="logitem?.ResultMessage">
      {{ logitem?.ResultMessage }}
    </div>
</div>


</template>

<style scoped>
  .activitylogitem {
    width: 100%;
  }
</style>
