<script setup lang="ts">

</script>

<template>
    <div class="registration-form">
        <div class="registration-form-body row">
            <div class="col-xs-12">
                <div class="box-row item-style">
                    <h3 class="thankyou-title">Leider ist ein Fehler aufgetreten.</h3>
                    <p class="thankyou-paragraph">Bitte versuche es sp&auml;ter nochmals.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    

    div[class^="col-"] {
        margin-bottom: 1rem;
    }

    .thankyou-title {
        padding-top: 2rem;
        padding-bottom: 1rem;
        text-align: center;
    }

    .thankyou-paragraph {
        text-align: center;
        margin-bottom: 2rem;
    }

</style>