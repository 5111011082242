<script setup lang="ts">

</script>

<template>
    <video style="width: 100%;max-width: 600px; border: 1px solid gray;" controls="false" >
        <source src="https://corinawidmerstorage.blob.core.windows.net/videos/Test 2.mp4" type="video/mp4" />
    </video>
</template>

<style scoped>

</style>