
<script setup lang="ts">import { onActivated, reactive, ref } from 'vue';
import EntityApi from '../../sdk/EntityApi';
import EmailTemplate from '../../sdk/models/EmailTemplate';

const props = defineProps({
  formstate: String,
  step: Object,
  onStepAdded: Function
})

const addStep = function() {
    props.onStepAdded!(props.step);
}

const data = reactive({ emailTemplates:[] as EmailTemplate[] });

const emailTemplatesApi = new EntityApi('emailtemplates');
data.emailTemplates = await emailTemplatesApi.retrieveAll();

onActivated(async () => {
    
});

</script>

<template>

<div class="overlay-content">
    <h3>Prozess Schritt</h3>

    <label for="stepname" class="label-style">Name</label>
    <input type="text" name="stepname" id="emailcode" class="textbox-style" v-model="step!.Name">

    <label for="stepType" class="label-style">Typ</label>
    <select name="stepType" v-model="step!.StepType" :disabled="formstate !== 'create'">
        <option value="sendemail">Email Senden</option>
        <option value="waitfor">Warten</option>
        <option value="logmessage">Log-Nachricht Schreiben</option>
    </select>

    <div v-if="step!.StepType == 'sendemail'">
        <label for="emailcode" class="label-style">Email-Code</label>
        <select  id="emailcode" name="emailcode" v-model="step!.emailCode">
            <option v-for="emailTemplate in data.emailTemplates" :value="emailTemplate.RowKey">{{ emailTemplate.Subject }}</option>
        </select>
    </div>

    <div v-if="step!.StepType == 'logmessage'">
        <label for="logmessage" class="label-style">Log-Message</label>
        <input type="text" name="logmessage" id="logmessage" class="textbox-style" v-model="step!.logMessage">
    </div>

    <div v-if="step!.StepType == 'waitfor'">
        <select name="WaitType" v-model="step!.waitType">
            <option value="WaitForMinutes">N Minuten warten</option>
            <option value="WaitForHours">N Stunden warten</option>
            <option value="WaitUntil">Warten bis</option>
        </select>
        <input type="text" name="WaitValue" id="WaitValue" class="textbox-style" v-model="step!.waitValue">
    </div>

    <div>
        <button name="submit" class="buttonstyle" @click="addStep" style="margin-top:3rem; margin-bottom: 1rem;">
            <span v-if="formstate === 'create'" class="">Hinzufügen</span>
            <span v-if="formstate !== 'create'" class="">Speichern</span>
        </button>
    </div>
    
</div>

</template>

<style scoped>

    .overlay-content {
        padding: 1rem;
    }

</style>
