<script setup lang="ts">import { reactive, ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import EntityApi from '../../sdk/EntityApi';
import Product from '../../sdk/models/Product';
import Registration from '../../sdk/models/Registration';
import FormatHelper from '../../sdk/helpers/FormatHelper';
import RegistrationProcesses from './RegistrationProcesses.vue';
import Overlay from '../Overlay.vue';

const router = useRouter();

const showOverlay = ref(false);
const overlayData = reactive({ registration:null as Registration|null })

const data = reactive({
    registrations:[] as Registration[],
    products:[] as Product[]
});

const productApi = new EntityApi('products');
data.products = await productApi.retrieveAll();

const registrationApi = new EntityApi('registrations');
data.registrations = await registrationApi.retrieveAll();

const deleteEntity = async function(registration: Registration) {
    await registrationApi.delete(registration);
    data.registrations = await registrationApi.retrieveAll();
}

const manageProcesses = async function(registration: Registration) {
    overlayData.registration = registration;
    showOverlay.value = true;
}

const resolveProductName = function(combinedProductName: string|null):string
{
    var splitted = combinedProductName?.split('^');
    if(splitted != null && splitted.length > 0){
        let productCode =splitted[1];
        const product = data.products.filter(product => product.RowKey == productCode);
        if(product.length > 0) {
            return product[0].Name!;
        } else {
            return "Product not found";
        }
        
    }else {
        return "Product not found";
    }
    
}

</script>

<template>
    <div class="form-container">
        
        <h3 class="form-body-title">Registrationen</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <div class="list-row list-header row">
                        <div class="col-xs-1">Registriert Am</div>
                        <div class="col-xs-2">Name</div>
                        <div class="col-xs-3">Email</div>
                        <div class="col-xs-3">Kommentar</div>
                        <div class="col-xs-2">Angemeldet Für</div>
                        <div class="col-xs-1"></div>
                    </div>

                    <div class="list-row row" v-for="registration in data.registrations">
                        <div class="col-xs-1" :title="FormatHelper.stringToDateTime(registration.Timestamp!)">{{ FormatHelper.stringToDate(registration.Timestamp!) }}</div>
                        <div class="col-xs-2">{{ registration.Name }}</div>
                        <div class="col-xs-3"><span style="width:3rem">{{ registration.AllowEmails ? '📧' : '🚫'}}</span>{{ registration.Email }}</div>
                        <div class="col-xs-3" style="white-space: pre-wrap;">{{ registration.Comment }}</div>
                        <div class="col-xs-2">{{resolveProductName(registration.PartitionKey)}}</div>
                        <div class="col-xs-1 list-button-column">

                            <button type="submit" name="startProcess" class="buttonstyle" style="height:3rem;margin-left: 1rem;" @click="manageProcesses(registration)">
                                <span class="" title="Manage Processes">&#x23f5;</span>
                            </button>

                            <button type="submit" name="deleteEntity" class="buttonstyle" style="height:3rem;margin-left: 1rem;" @click="deleteEntity(registration)">
                                <span class="" title="Delete Registration">&#x2718;</span>
                            </button>
                        </div>
                    </div>
                    <div style="height: 1rem;"></div>
                </div>
            </div>
        </div>

        <Overlay v-model:isvisible="showOverlay" height="70%" width="70%">
            <RegistrationProcesses :registration="overlayData.registration!" @closeOverlay="showOverlay=false"></RegistrationProcesses>
        </Overlay>
    </div>
</template>

<style scoped>

.list-row div[class^="col-"] {
    display: flex;
    align-items: center;
}

.list-button-column {
    align-content: right;
    display: flex;
    justify-content: right;
}

</style>
