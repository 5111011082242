<script setup lang="ts">
import { onActivated, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import ProcessStep from '../../sdk/models/ProcessStep';
import { EntityFormControl } from '../entityform/EntityFormControl';
import Overlay from '../Overlay.vue';
import AddProcessStep from './AddProcessStep.vue'

const formControl = new EntityFormControl('processes');

const route = useRoute();

const showOverlay = ref(false);

const processData = reactive({ steps: [] as ProcessStep[]});

const addProcessStepData = reactive({ formstate: 'create', step: new ProcessStep()})

onActivated(async () => {
    await formControl.initializeAsync(route.params.rowKey as string);
    formControl.addOnSave(saveProcessStepsOnEntity);
    if(route.params.rowKey) {
        processData.steps = JSON.parse(formControl.data.entity.ProcessStepsSerialized);
    } 
});

const addStep = function() {
    addProcessStepData.formstate = 'create';
    addProcessStepData.step = new ProcessStep();
    showOverlay.value = true;
}

const editStep = function(processStep: ProcessStep) {
    addProcessStepData.formstate = 'update';
    addProcessStepData.step = processStep;
    showOverlay.value = true;
}

const onStepAdded = function(processStep: ProcessStep) {
    showOverlay.value = false;
    if(addProcessStepData.formstate === 'create') {
        processData.steps.push(processStep);
    }
}

const onStepDeleted = function(processStep: ProcessStep) {
    processData.steps = processData.steps.filter(step => step.Name !== processStep.Name);
}

const saveProcessStepsOnEntity = function() {
    formControl.data.entity.ProcessStepsSerialized = JSON.stringify(processData.steps);
}

const moveProcessStep = function(processStep: ProcessStep, delta:number) {
    moveArrayItem(processData.steps, processStep, delta);
}

const moveArrayItem = function(array:Array<any>, element:any, delta:number) {
  var index = array.indexOf(element);
  var newIndex = index + delta;
  if (newIndex < 0  || newIndex == array.length) return; //Already at the top or bottom.
  var indexes = [index, newIndex].sort(); //Sort the indixes
  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
};

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Prozess Erfassung</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">

                    <label for="processCode" class="label-style">Prozess-Code</label>
                    <input type="text" name="processCode" id="processCode" class="textbox-style" v-model="formControl.data.entity.RowKey" :disabled="formControl.data.isCreate == false">
                    <label for="processName" class="label-style">Prozess-Name</label>
                    <input type="text" name="processName" id="processName" class="textbox-style" v-model="formControl.data.entity.Name">

                     <label class="label-style" style="margin-top:2rem;">Prozess-Schritte</label>

                    <div v-for="processStep in processData.steps" class="processStep row">
                        <div class="col-xs-2">
                            {{ processStep.StepType }}
                        </div>
                        <div class="col-xs-3">
                            <div class="box-row">{{ processStep.Name }}</div>
                        </div>
                        <div class="col-xs-4">
                            <div v-if="processStep.StepType == 'sendemail'">{{ (processStep as any).emailCode}}</div>
                            <div v-if="processStep.StepType == 'waitfor'">{{ (processStep as any).waitType}} {{ (processStep as any).waitValue }}</div>
                        </div>
                        <div class="col-xs-3">
                            <div style="float:right;display:flex;">
                                
                                <button  name="editstep" class="buttonstyle" @click="editStep(processStep)">
                                    <span class="">&#x270e;</span>
                                </button>                                
                                <button  name="movedown" class="buttonstyle" style="margin-left:0.5rem" @click="moveProcessStep(processStep, 1)">
                                    <span class="">&#x2193;</span>
                                </button>
                                <button  name="moveup" class="buttonstyle" style="margin-left:0.5rem" @click="moveProcessStep(processStep, -1)">
                                    <span class="">&#x2191;</span>
                                </button>
                                <button  name="deletestep" class="buttonstyle" style="margin-left:0.5rem" @click="onStepDeleted(processStep)">
                                    <span class="">&#x2718;</span>
                                </button>
                            </div>
                            
                        </div>
                    </div>

                    <button name="submit" class="buttonstyle row" @click="addStep" style="margin-left:0rem;margin-top:1rem; margin-bottom: 1rem;">                            
                        <span>Hinzufügen</span>
                    </button>
                    
                     
                    <div style="display: flex;">
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.cancel" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Abbrechen</span>
                        </button>
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.saveEntity" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span class="">Speichern</span>
                        </button>
                    </div>

                           
                </div>
            </div>
        </div>

        <Overlay v-model:isvisible="showOverlay">
            <AddProcessStep :step="addProcessStepData.step" :formstate="addProcessStepData.formstate" :onStepAdded="onStepAdded"></AddProcessStep>
        </Overlay>

        
    </div>


</template>

<style scoped>
    .processStep {
        padding: 0.5rem;
        border: 1px solid silver;
        margin: 0;
        margin-top:0.5rem;
    }
    .overlay-content {
        padding: 1rem;
    }

</style>
