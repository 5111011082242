<script setup lang="ts">
import { ref, reactive } from '@vue/reactivity';
import { onActivated } from 'vue'
import { useRoute } from 'vue-router'
import { EntityFormControl } from '../entityform/EntityFormControl';
import Login from '../../sdk/models/Login';
import Group from '../../sdk/models/Group'
import EntityApi from '../../sdk/EntityApi';
import Overlay from '../Overlay.vue';
import GroupRight from '../../sdk/models/GroupRight';

const onSaveEntity = function() {
    var memberKeys:string[] = [];
    data.members.forEach(member => {
        memberKeys.push(member.RowKey!);
    });
    formControl.data.entity.MembersSerialized = JSON.stringify(memberKeys);

    var rightsIds:number[] = [];
    data.rights.forEach(right => {
        rightsIds.push(right.Id!);
    });
    formControl.data.entity.RightsSerialized = JSON.stringify(rightsIds);
}

const formControl = new EntityFormControl('groups');
formControl.addOnSave(onSaveEntity);

const route = useRoute();

class GroupFormData {
    allLogins: Login[] = [];
    allGroupRights: GroupRight[] = [
        {
            Id: 1,
            Name: "HappyHomeschoolingWoche1"
        }, 
        {
            Id: 2,
            Name: "HappyHomeschoolingWoche2"
        }, 
        {
            Id: 3,
            Name: "HappyHomeschoolingWoche3"
        }, 
        {
            Id: 4,
            Name: "HappyHomeschoolingWoche4"
        }, 
        {
            Id: 5,
            Name: "HappyHomeschoolingWoche5"
        }, 
        {
            Id: 6,
            Name: "HappyHomeschoolingWoche6"
        }, 
        {
            Id: 7,
            Name: "HappyHomeschoolingQA"
        }, 
        {
            Id: 8,
            Name: "HappyHomeschoolingForum"
        },
        
        
        {
            Id: 10000,
            Name: "User"
        },
        {
            Id: 20000,
            Name: "Admin"
        },
    ];
    addMemberSelected: Login|null = null;
    addGroupSelected: GroupRight|null = null;
    members: Login[] = [];
    rights: GroupRight[] = [];
}

const data = reactive(new GroupFormData());

const loginApi = new EntityApi('logins');
data.allLogins = await loginApi.retrieveAll();

onActivated(async () => {
    await formControl.initializeAsync(route.params.rowKey as string);
    if(!formControl.data.isCreate)
    {
        deserializeMembers();
        deserializeGroupRights();
    }
});

const deserializeMembers = function() {
    const membersSerialized = (formControl.data.entity as Group).MembersSerialized;
    if(!membersSerialized) { return; }

    const membersArray:string[] = JSON.parse(membersSerialized);
    membersArray.forEach(memberKey => {
        const login = data.allLogins.filter(login => login.RowKey == memberKey);
        if(login.length == 1) {
            data.members.push(login[0]);
        }
    });
}

const deserializeGroupRights = function() {
    const rightsSerialized = (formControl.data.entity as Group).RightsSerialized;
    if(!rightsSerialized) { return; }

    const rightsArray:number[] = JSON.parse(rightsSerialized);
    rightsArray.forEach(groupId => {
        const right = data.allGroupRights.filter(login => login.Id == groupId);
        if(right.length == 1) {
            data.rights.push(right[0]);
        }
    });
}

const isAddMembersOverlayVisible = ref(false);
const addGroupMembers = function() {
    isAddMembersOverlayVisible.value = true;
}

const deleteGroupMember = function(memberToDelete: Login) {
    data.members = data.members.filter(m => m.RowKey !== memberToDelete.RowKey);
}

const addMemberToGroup = function(user: Login) {
    isAddMembersOverlayVisible.value = false;
    data.members.push(user);
}

const isAddRightsOverlayVisible = ref(false);
const addGroupRights = function() {
    isAddRightsOverlayVisible.value = true;
}

const deleteGroupRight = function(groupRight: GroupRight) {
    data.rights = data.rights.filter(m => m.Id !== groupRight.Id);
}

const addGroupRightToGroup = function(groupRight: GroupRight) {
    isAddRightsOverlayVisible.value = false;
    data.rights.push(groupRight);
}

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Gruppen Erfassung</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">

                    <label for="groupName" class="label-style">Name</label>
                    <input type="text" name="groupName" id="groupName" class="textbox-style" v-model="formControl.data.entity.Name">

                    <div class="groupmembers">
                        <div class="groupmembers-header" style="display:flex;justify-content:space-between;padding-bottom:1rem">
                            <div>
                                <h4>Mitglieder</h4>
                            </div>
                            <div>
                                <button type="submit" name="addGroupMembers" class="buttonstyle" @click="addGroupMembers">
                                    <span class="">Hinzufügen</span>
                                </button>
                            </div>
                        </div>

                        <div style="margin-top: 1rem;">
                            <div style="display:flex;justify-content:space-between;height: 3.5rem;" v-for="member in data.members">
                                <div>{{ member.User }}</div>
                                <div>
                                    <button type="submit" name="addGroupMembers" class="buttonstyle" @click="deleteGroupMember(member)">
                                        <span class="">Löschen</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="grouprights">
                        <div class="grouprights-header" style="display:flex;justify-content:space-between;padding-bottom:1rem">
                            <div>
                                <h4>Berechtigungen</h4>
                            </div>
                            <div>
                                <button type="submit" name="addGroupRights" class="buttonstyle" @click="addGroupRights">
                                    <span class="">Hinzufügen</span>
                                </button>
                            </div>
                        </div>

                        <div style="margin-top: 1rem;">
                            <div style="display:flex;justify-content:space-between;height: 3.5rem;" v-for="groupRight in data.rights">
                                <div>{{ groupRight.Name }}</div>
                                <div>
                                    <button type="submit" name="addGroupRights" class="buttonstyle" @click="deleteGroupRight(groupRight)">
                                        <span class="">Löschen</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div style="display: flex;">
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.cancel" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Abbrechen</span>
                        </button>
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.saveEntity" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span class="">Speichern</span>
                        </button>
                    </div>

                    <Overlay v-model:isvisible="isAddMembersOverlayVisible" height="600px" width="400px">
                        <h4>Add Members</h4>
                        <div v-for="user in data.allLogins" :class="{ 'selected-item': data.addMemberSelected == user }" @click="data.addMemberSelected = user">{{ user.User }}</div>
                        <div></div>
                        <button type="submit" name="submit" class="buttonstyle" :disabled="data.addMemberSelected == null" @click="addMemberToGroup(data.addMemberSelected!)" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span class="">Hinzufügen</span>
                        </button>
                    </Overlay>

                    <Overlay v-model:isvisible="isAddRightsOverlayVisible">
                        <h4>Add Rights</h4>
                        <div v-for="groupRight in data.allGroupRights" :class="{ 'selected-item': data.addGroupSelected == groupRight }" @click="data.addGroupSelected = groupRight">{{ groupRight.Name }}</div>
                        <div></div>
                        <button type="submit" name="submit" class="buttonstyle" :disabled="data.addGroupSelected == null" @click="addGroupRightToGroup(data.addGroupSelected!)" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span class="">Hinzufügen</span>
                        </button>
                    </Overlay>
                </div>
            </div>
        </div>
        
        
    </div>


</template>

<style scoped>

    .selected-item {
        background-color: var(--selected-item);
    }

    div[class^="col-"] {
        margin-bottom: 1rem;
    }

    .label-style {
        font-weight: 400;
        line-height: 1.5;
        font-family: Roboto,sans-serif;
        color: #323335;
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }

    .groupmembers, .grouprights {
        margin-top: 4rem;
    }

    .groupmembers-header, .grouprights-header {
        border-bottom: 1px solid var(--spacer-color);
    }



</style>
