import { reactive } from "vue";
import { useRouter } from "vue-router";
import EntityApi from "../../sdk/EntityApi";

export class EntityFormControl {
    data = reactive({ 
        entity: {} as any,
        isCreate: false
    })

    entityPath:string;
    api:EntityApi;
    router:any;

    onSave:Function|null = null;

    constructor(entityPath:string) {
        this.entityPath = entityPath;
        this.api = new EntityApi(entityPath);
        this.router = useRouter();
    }

    async initializeAsync(rowKey: string) {
        if(rowKey) {
            this.data.entity = await this.api.retrieve(rowKey);
        } 
    
        this.data.isCreate = this.data.entity.RowKey == null;
    }

    async saveEntity() {
        if(this.onSave !=null) {
            this.onSave();
        }

        if(this.data.isCreate) {
            await this.api.create(this.data.entity);
        } else {
            await this.api.update(this.data.entity);
        }
    
        this.data.entity = {};
        this.router.push("/admin/" + this.entityPath);
    }

    addOnSave(onSaveMethod:Function) {
        this.onSave = onSaveMethod;
    }
    
    async cancel() {
        this.data.entity = {};
        this.router.back();
    }
}