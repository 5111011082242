<script setup lang="ts">
import { onActivated } from 'vue'
import { useRoute } from 'vue-router'
import AzureEnvironment from '../../sdk/AzureEnvironment';
import { EntityFormControl } from '../entityform/EntityFormControl';

const formControl = new EntityFormControl('assets');

const route = useRoute();

onActivated(async () => {
    await formControl.initializeAsync(route.params.rowKey as string);
})

const convertBase64 = (file:any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const onFileChange = async function(e:any) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
        return;

    const file = e.target.files[0];
    const base64Content = await convertBase64(file);

    formControl.data.entity.FileContent = base64Content;
} 

</script>

<template>
    <div class="form-container">

        <h3 class="form-body-title">Asset Form</h3>

        <div class="form-container-body row">
            <div class="col-xs-12">
                <div class="box-row form-container-style cardshadow">
                    <label for="entityCode" class="label-style">Asset-Code</label>
                    <input type="text" name="entityCode" id="entityCode" class="textbox-style" v-model=" formControl.data.entity.RowKey" :disabled="formControl.data.isCreate == false">
                    <label for="entityName" class="label-style">Datei-Name</label>
                    <input type="text" name="entityName" id="entityName" class="textbox-style" v-model="formControl.data.entity.Name">
                    
                    <label for="assetFile" class="label-style">Datei</label>
                    <input id="assetFile" name="assetFile" type="file" v-on:change="onFileChange">

                    <div style="display: flex;">
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.cancel" style="margin-top:3rem; margin-bottom: 1rem;">
                            <span class="">Abbrechen</span>
                        </button>
                        <button type="submit" name="submit" class="buttonstyle" @click="formControl.saveEntity" style="margin-top:3rem; margin-bottom: 1rem;margin-left: 1rem;">
                            <span class="">Speichern</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>       
        
    </div>


</template>

<style scoped>

    div[class^="col-"] {
        margin-bottom: 1rem;
    }

    .label-style {
        font-weight: 400;
        line-height: 1.5;
        font-family: Roboto,sans-serif;
        color: #323335;
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }    

</style>
