export enum GroupRightEnum {
    HappyHomeschoolingWoche1 = 1,
    HappyHomeschoolingWoche2 = 2,
    HappyHomeschoolingWoche3 = 3,
    HappyHomeschoolingWoche4 = 4,
    HappyHomeschoolingWoche5 = 5,
    HappyHomeschoolingWoche6 = 6,
    HappyHomeschoolingQA = 7,
    HappyHomeschoolingForum = 8,

    User = 10000,
    Admin = 20000,
  }