<script setup lang="ts">import { onActivated, ref, watch } from 'vue';

const props = defineProps({
  isvisible: Boolean,
  isClosable: { type: Boolean, default: true },
  height: { type: String, default: "400px" },
  width: { type: String, default: "300px" },
  roundedCorners: { type: String, default: "0rem" }
})

const emit = defineEmits(['update:isvisible'])

const onContainterBoxClicked = function(e:any) {
    if(!props.isClosable || e.srcElement.id != "overlayContainer") { return; }
    emit('update:isvisible', false);
    document.body.style.overflow = 'auto';
}

watch(() => props.isvisible, (newValue, oldValue) => {
    document.body.style.overflow = newValue === true ? 'hidden' : 'auto';
});
</script>

<template>
<div    id="overlayContainer" class="container-box" :class="[props.isvisible ? 'container-box' : 'no-show']" @click="onContainterBoxClicked">
  <div class="overlay-box" :style="{ height: props.height, width: props.width, borderRadius: props.roundedCorners }" v-if="isvisible" >
      <slot ></slot>
  </div>
</div>
</template>

<style scoped>

.container-box {
    top: 0;
    left: 0;
    position: fixed;
    background-color:#000;
    width:100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.overlay-box {
   background-color:#fff;
    cursor: default;
    z-index: 1000;
    border: 1px solid lightgrey;
    cursor: default;
    overflow-y: auto;
}

.no-show {
    display: none;
}

@media only screen and (max-width: 629px) {
    .overlay-box {
        height: 95% !important;
        width: 95% !important;
    }
}
</style>





